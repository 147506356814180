import React from 'react'
import { Wrapper } from './styles'
import PlayerForm from '../Form'
import { makeStyles, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IProfile, PlayerData } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface AddPlayerProps {
  hideAddPlayer: () => void
  handlerAddPlayer: (data: PlayerData, hideForm: () => void) => void
  showProfiles: () => void
  profile: IProfile | undefined
}

const AddPlayer: React.FC<AddPlayerProps> = ({
  hideAddPlayer,
  handlerAddPlayer,
  showProfiles,
  profile,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const [t] = useTranslation()

  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideAddPlayer}>
        <CloseIcon />
      </IconButton>
      <h3>{t('ru.players.add_form')}</h3>

      <PlayerForm
        profile={profile}
        showProfiles={showProfiles}
        onSubmit={handlerAddPlayer}
        cancelBtnEvent={hideAddPlayer}
        cancelBtnText={t('ru.common.cancel_btn')}
        submitBtnText={t('ru.common.add_btn')}
        hideForm={(player_id?: number) => {
          hideAddPlayer()
          history.push(`players?player_id=${player_id}`)
        }}
      />
    </Wrapper>
  )
}

export default AddPlayer
