import React from 'react'

import Preloader from '../../Common/Preloader'
import {
  List,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  makeStyles,
  Box,
  ButtonGroup,
  Button,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import Item from './Item'
import { IProfile, ProfileData } from '../../../interfaces'
import Overlay from '../../Common/Overlay/index'
import AddProfile from '../Add'
import {
  handlerAddProfile,
  handlerChangeProfile,
  profileSelector,
} from '../../../redux/profileReducer'
import ChangeProfile from '../Change'
import { useShowBlock } from '../../../hooks/useShowBlock'
import Profile from '../View'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
  item: {
    display: 'flex',
    width: '100%',
  },
  body: { height: '2em', overflowY: 'auto' },
}))

interface ProfilesListProps {
  setCurrentProfile: (id: number) => void
  hideProfiles: () => void
}

const ProfilesList: React.FC<ProfilesListProps> = ({ setCurrentProfile, hideProfiles }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [t] = useTranslation()

  const { items: profiles, loading } = useSelector(profileSelector)

  const [selectedProfile, setSelectedProfile] = React.useState<number>(-1)
  const [profileId, setProfileId] = React.useState<number>(-1)

  const {
    showBlock: showChangeProfile,
    isBlockShown: isChangeProfile,
    hideBlock: hideChangeProfile,
  } = useShowBlock()

  const {
    showBlock: showAddProfile,
    isBlockShown: isAddProfile,
    hideBlock: hideAddProfile,
  } = useShowBlock()

  const { showBlock: showProfile, isBlockShown: isProfile, hideBlock: hideProfile } = useShowBlock()

  return loading ? (
    <Preloader absolute={false} />
  ) : (
    <List>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="right">{t('ru.profiles.fields.first_name')}</TableCell>
              <TableCell align="right">{t('ru.profiles.fields.sure_name')}</TableCell>
              <TableCell align="right">{t('ru.profiles.fields.last_name')}</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>
            {profiles &&
              profiles.map((profile) => (
                <Item
                  key={profile.id}
                  profile={profile}
                  selectedProfile={selectedProfile}
                  setSelectedProfile={setSelectedProfile}
                  setProfileId={setProfileId}
                  showProfile={showProfile}
                  showChangeProfile={showChangeProfile}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="center">
        <ButtonGroup>
          <Button onClick={hideProfiles} variant="contained">
          {t('ru.common.cancel_btn')}
          </Button>
          <Button onClick={showAddProfile} variant="contained">
          {t('ru.common.add_btn')}
          </Button>
          <Button
            onClick={() => {
              setCurrentProfile(selectedProfile)
              hideProfiles()
            }}
            variant="contained"
          >
            {t('ru.common.select_btn')}
          </Button>
        </ButtonGroup>
      </Box>
      {isAddProfile && (
        <Overlay>
          <AddProfile
            handlerAddProfile={(data: ProfileData, hideForm: () => void) =>
              dispatch(handlerAddProfile(data, hideForm))
            }
            hideAddProfile={hideAddProfile}
          />
        </Overlay>
      )}
      {isChangeProfile && (
        <Overlay>
          <ChangeProfile
            handlerChangeProfile={(
              data: ProfileData,
              hideForm: () => void,
              id: number | undefined
            ) => id && dispatch(handlerChangeProfile(data, hideForm, id))}
            hideChangeProfile={hideChangeProfile}
            profile={profiles?.find((i: IProfile) => i.id === profileId)}
          />
        </Overlay>
      )}
      {isProfile && (
        <Overlay>
          <Profile
            hideProfile={hideProfile}
            profile={profiles.find((i: IProfile) => i.id === profileId)}
          />
        </Overlay>
      )}
    </List>
  )
}

export default ProfilesList
