import { configureStore, ThunkAction, Store, Action, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './rootReducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()]
})

export type AppThunk = ThunkAction<void, Store, unknown, Action<string>>;

export default store