import React from 'react'
import { Wrapper, TableHeader } from './styles'

import Item from './Item'
import Preloader from '../../Common/Preloader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Button from '@material-ui/core/Button'
import {
  playerSelector,
  deletePlayerItem,
  handlerAddPlayer,
} from '../../../redux/playerReducer'
import { useDispatch, useSelector } from 'react-redux'
import { ListItem, Box, FormLabel, Select, MenuItem, Input, makeStyles } from '@material-ui/core'
import Confirm from '../../Common/Confirm'
import Overlay from '../../Common/Overlay/index'
import { IPlayer, isConfirmType, IProfile, PlayerData } from '../../../interfaces'
import { authSelector } from '../../../redux/authReducer'
import { findPermission } from '../../../utils/find'
import { profileSelector } from '../../../redux/profileReducer'
import { hallSelector } from '../../../redux/hallReducer'
import Player from '../View'
import AddPlayer from '../Add'
import Profiles from '../../Profiles'
import { useShowBlock } from '../../../hooks/useShowBlock'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { sortedList } from '../../../utils/sort'
import Bind from '../Bind'

const useStyles = makeStyles(() => ({
  inputRoot: {
    '& .MuiInput-underline.Mui-disabled:before': {
      border: 'none',
    },
  },
}))

const PlayersList: React.FC = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const history: any = useHistory()
  const [t] = useTranslation()

  const {
    assigments: { items },
  } = useSelector(authSelector)
  const { items: players, loading } = useSelector(playerSelector)
  const { items: profiles, form: formLoading } = useSelector(profileSelector)
  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const [currentPlayer, setCurrentPlayer] = React.useState<null | number>(null)
  const [currentProfile, setCurrentProfile] = React.useState<number>(-1)

  const [selectedSort, setSelectedSort] = React.useState<string>('id')
  const [hallsGroup, setHallsGroup] = React.useState<number>(0)
  const [sortDir, setSortDir] = React.useState<boolean>(true)

  const [isConfirm, setIsConfirm] = React.useState<isConfirmType>({ open: false })

  const [isChangeMode, setIsChangeMode] = React.useState(false)

  const { showBlock: showPlayer } = useShowBlock()

  const {
    showBlock: showChangePlayer,
    // isBlockShown: isChangePlayer,
    // hideBlock: hideChangePlayer,
  } = useShowBlock()

  const {
    showBlock: showAddPlayer,
    isBlockShown: isAddPlayer,
    hideBlock: hideAddPlayer,
  } = useShowBlock()

  const {
    showBlock: showProfiles,
    isBlockShown: isProfiles,
    hideBlock: hideProfiles,
  } = useShowBlock()

  const {
    showBlock: showBind,
    isBlockShown: isBind,
    hideBlock: hideBind,
  } = useShowBlock()

  const sortButton = (name: string, label: string) => {
    return (
      <ListItem
        button
        component="span"
        selected={name === selectedSort || `-${name}` === selectedSort}
        style={{ cursor: 'pointer', justifyContent: 'center' }}
        onClick={() => {
          if (selectedSort === name) {
            setSortDir(!sortDir)
          } else {
            setSelectedSort(name)
          }
        }}
      >
        {label}
        {selectedSort === `${name}` && (sortDir ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </ListItem>
    )
  }

  const [search, setSearch] = React.useState<any>(null)
  const filteredPlayers = () => {
    if (search) {
      return players.filter((player) => {
        const idFilter = search?.id ? player.id === Number(search?.id) : true
        const nameFilter = !!search?.name
          ? player.name.toLowerCase().indexOf(search.name.toLowerCase()) > -1
          : true
        return idFilter && nameFilter
      })
    } else {
      return players
    }
  }

  const renderAll = sortedList(selectedSort, sortDir, filteredPlayers()).map(
    (item: IPlayer, id: number) => {
      return (
        <Item
          key={id}
          id={item.id}
          login={item.name}
          profile={item.profile}
          tableConnection={item.tableConnection}
          hall={halls.find(h => h.id === item.hall_id)}
          setCurrentPlayer={setCurrentPlayer}
          setCurrentProfile={setCurrentProfile}
          showPlayer={showPlayer}
          showChangePlayer={showChangePlayer}
          setIsConfirm={setIsConfirm}
          setIsChangeMode={setIsChangeMode}
          showBind={showBind}
        />
      )
    }
  )

  const renderGroup =
    halls &&
    halls.map((item) => {
      return (
        !!(players && players.filter((i) => i.hall_id === item.id).length) && (
          <React.Fragment key={item.id}>
            <Box display="flex" justifyContent="center">
              <FormLabel>{item.name}</FormLabel>
            </Box>
            {sortedList(selectedSort, sortDir, filteredPlayers()).map((player) => {
              return (
                item.id === player.hall_id && (
                  <Item
                    key={player.id}
                    id={player.id}
                    login={player.name}
                    profile={player.profile}
                    hall={player.hall}
                    tableConnection={player.tableConnection}
                    setCurrentPlayer={setCurrentPlayer}
                    setCurrentProfile={setCurrentProfile}
                    showPlayer={showPlayer}
                    showChangePlayer={showChangePlayer}
                    setIsConfirm={setIsConfirm}
                    setIsChangeMode={setIsChangeMode}
                    showBind={showBind}
                  />
                )
              )
            })}
          </React.Fragment>
        )
      )
    })

  const location: any = useLocation()
  const id = new URLSearchParams(location.search).get('player_id')
  React.useEffect(() => {
    if (!!id) {
      setCurrentPlayer(Number(id))
    } else {
      setCurrentPlayer(null)
    }
  }, [id])

  const renderList = () => {
    return hallsGroup ? renderGroup : renderAll
  }

  return (
    <Wrapper>
      <Box component="div" style={{ minWidth: '550px' }}>
        <Box display="flex" justifyContent="space-between">
          <h2>{t('ru.players.title')}</h2>
          <Select value={hallsGroup} onChange={(e: any) => setHallsGroup(e.target.value)}>
            <MenuItem value={0}>{t('ru.common.selectors.all')}</MenuItem>
            <MenuItem value={1}>{t('ru.common.selectors.hall')}</MenuItem>
          </Select>
          {findPermission(items, 'createPlayer') && (
            <Button onClick={showAddPlayer} variant="contained">
              {t('ru.common.add_btn')}
            </Button>
          )}
        </Box>
        <div>
          <TableHeader>
            {sortButton('id', 'id')}
            {sortButton('name', t('ru.players.fields.name'))}
            <ListItem component="span">{t('ru.players.fields.profile')}</ListItem>
            {/* {sortButton('profile', t('ru.players.fields.profile'))} */}
            {sortButton('hall_id', t('ru.players.fields.hall'))}
            <ListItem component="span"></ListItem>
          </TableHeader>
          <Box style={{ background: '#fff', padding: '5px 0' }}>
            <TableHeader>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      id: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem component="span">
                <Input
                  style={{ background: '#efeff0' }}
                  onChange={(e) => {
                    setSearch({
                      ...search,
                      name: e.target.value,
                    })
                  }}
                />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span">
                <Input disabled />
              </ListItem>
              <ListItem className={classes.inputRoot} component="span"></ListItem>
            </TableHeader>
          </Box>
          <ul>{loading ? <Preloader absolute={false} /> : renderList()}</ul>
        </div>
      </Box>
      {!!isAddPlayer && (
        <Overlay>
          <AddPlayer
            profile={profiles.find((i: IProfile) => i.id === currentProfile)}
            showProfiles={showProfiles}
            hideAddPlayer={() => {
              hideAddPlayer()
              setCurrentProfile(-1)
            }}
            handlerAddPlayer={(data: PlayerData, hideForm: () => void) =>
              dispatch(handlerAddPlayer(data, hideForm))
            }
          />
        </Overlay>
      )}
      {!!id && !!currentPlayer && (
        <Overlay>
          <Player
            setCurrentProfile={setCurrentProfile}
            profile={profiles.find((i) => i.id === currentProfile)}
            hidePlayer={() => {
              const query = new URLSearchParams(location.search)
              query.delete('player_id')
              history.push({
                pathname: location.pathname,
                search: query.toString(),
                state: {
                  prevPaths:
                    location.state && location.state.prevPaths ? location.state.prevPaths : [],
                },
              })
            }}
            showProfiles={showProfiles}
            player={players?.find((i: IPlayer) => i.id === currentPlayer)}
            isChangeMode={isChangeMode}
            setIsChangeMode={setIsChangeMode}
          />
        </Overlay>
      )}

      {!!isProfiles && (
        <Overlay>
          <Profiles hideProfiles={hideProfiles} setCurrentProfile={setCurrentProfile} />
        </Overlay>
      )}
      {!!isConfirm.open && (
        <Overlay>
          <Confirm
            text={`${t('ru.common.confirm.delete')} ${isConfirm.name}?`}
            loading={formLoading.loading}
            cancel={() => {
              setIsConfirm({ open: false })
            }}
            submit={() => {
              dispatch(deletePlayerItem(isConfirm.id, setIsConfirm))
            }}
          />
        </Overlay>
      )}
      {isBind && currentPlayer && (
        <Overlay>
          <Bind hideBind={hideBind} player_id={currentPlayer}/>
        </Overlay>
      )}
    </Wrapper>
  )
}

export default PlayersList
