import React from 'react';
import { Wrapper, MenuItem } from './styles';
import { Main, Business, Users, Terminals, Reports, Admin } from '../Common/MenuIcons';

import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
// import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import JackpotIcon from '../../assets/icons/jackpot.svg';

import { Link, useLocation } from 'react-router-dom';
import { authSelector } from '../../redux/authReducer';
import { useSelector } from 'react-redux';
import { findPermission } from '../../utils/find';
import { useTranslation } from 'react-i18next';
import { Collapse, List, ListItem, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    color: '#666',
    fontSize: '70%',
    paddingTop: 2,
    paddingBottom: 1
  },
  bold: {
    fontWeight: 'bold'
  }
}));

const LeftPanel: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const [t] = useTranslation();
  const {
    assigments: { items },
    role: { item: userRole },
  } = useSelector(authSelector);
  const [pathname, setPathname] = React.useState<string>('');
  const [isReports, setIsReports] = React.useState<boolean>(false);
  const isActive = (path: string) => pathname.indexOf(path) !== -1;
  React.useEffect(() => {
    setPathname(location.pathname);
  }, [location.pathname]);

  return <Wrapper>
    <Link className="link" to="/">
      <MenuItem active={pathname === '/'} onClick={() => { setIsReports(false); setPathname('/'); }}>
        <Main />
        {t('ru.menu_panel.home')}
      </MenuItem>
    </Link>
    {(findPermission(items, 'viewUserList') || findPermission(items, 'userChildrenList')) && <Link className="link" to="/users">
      <MenuItem active={isActive('/users')} onClick={() => { setIsReports(false); setPathname('/users'); }}>
        <Users />
        {t('ru.menu_panel.users')}
      </MenuItem>
    </Link>}
    {findPermission(items, 'viewHallList') && <Link className="link" to="/halls">
      <MenuItem active={isActive('/halls')} onClick={() => { setIsReports(false); setPathname('/halls'); }}>
        <Business />
        {t('ru.menu_panel.halls')}
      </MenuItem>
    </Link>}
    {findPermission(items, 'viewPlayerList') && <Link className="link" to="/players">
      <MenuItem active={isActive('/players')} onClick={() => { setIsReports(false); setPathname('/players'); }}>
        <Terminals />
        {t('ru.menu_panel.players')}
      </MenuItem>
    </Link>}
    {userRole && userRole === 'administrator' && <Link className="link" to="/terminals">
      <MenuItem active={isActive('/terminals')} onClick={() => { setIsReports(false); setPathname('/terminals'); }}>
        <AssessmentIcon style={{ margin: '0 2vh', fontSize: '15px' }} />
        {t('ru.menu_panel.terminals')}
      </MenuItem>
    </Link>}
    {findPermission(items, 'viewGamblingTableList') && <Link className="link" to="/tables">
      <MenuItem active={isActive('/tables')} onClick={() => { setIsReports(false); setPathname('/tables'); }}>
        <ImportantDevicesIcon style={{ margin: '0 2vh', fontSize: '15px' }} />
        {t('ru.menu_panel.tables')}
      </MenuItem>
    </Link>}
    {findPermission(items, 'jackpotIndex') && <Link className="link" to="/jackpots">
      <MenuItem active={isActive('/jackpots')} onClick={() => setPathname('/jackpots')}>
        <img src={JackpotIcon} alt="jackpot icon" width="51" height="51" style={{ margin: '0 2vh', fontSize: '15px', width: '1em', height: '1em' }} />
        {t('ru.menu_panel.jackpots')}
      </MenuItem>
    </Link>}
    {<>
      <MenuItem active={isActive('/reports')} onClick={() => { /*setPathname('/reports')*/ setIsReports(!isReports); }}>
        <Reports />
        {t('ru.menu_panel.reports')}
        {isReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </MenuItem>
      <Collapse in={isReports} timeout="auto">
        <List component="div" disablePadding>
          {findPermission(items, 'hallReportAmount') && <Link className="link" to="/reports/main">
            <ListItem className={classes.nested} onClick={() => setPathname('/reports/halls')}>
              <span style={pathname === '/reports/main' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                {t('ru.reports.halls_title')}
              </span>
            </ListItem>
          </Link>}
          <Link className="link" to="/reports/cashboxes">
            <ListItem className={classes.nested} onClick={() => setPathname('/reports/cashboxes')}>
              <span style={pathname === '/reports/cashboxes' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                {t('ru.reports.shifts_title')}
              </span>
            </ListItem>
          </Link>
          {userRole && userRole !== 'cashier' && <Link className="link" to="/reports/games">
            <ListItem className={classes.nested} onClick={() => setPathname('/reports/games')}>
              <span style={pathname === '/reports/games' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}} >
                {t('ru.reports.games_title')}{' '}
              </span>
            </ListItem>
          </Link>}
          {userRole && userRole !== 'cashier' && <Link className="link" to="/reports/user">
            <ListItem className={classes.nested} onClick={() => setPathname('/reports/user')}>
              <span style={pathname === '/reports/user' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                {t('Отчет пользователя')}{' '}
              </span>
            </ListItem>
          </Link>}
        </List>
      </Collapse>
    </>}
    {/* {true && (
      <Link className="link" to="/faq">
        <MenuItem active={isActive('/faq')} onClick={() => { setIsReports(false); setPathname('/faq'); }}>
          <HelpOutlineIcon style={{ margin: '0 2vh', fontSize: '15px' }} />
          FAQ
        </MenuItem>
      </Link>
    )}
    <Link to="/issues">
      <MenuItem active={isActive('/issues')} onClick={() => { setIsReports(false); setPathname('/issues'); }}>
        <ReportProblemIcon style={{ margin: '0 2vh', fontSize: '15px' }} />
        {t('ru.menu_panel.feedback')}
      </MenuItem>
    </Link> */}
    {/* <Link to="/settings">
                <MenuItem active={isActive('/settings')} onClick={() => setPathname('/settings')}>
                    <Setup />
                    Настройки
                </MenuItem>
            </Link> */}
    {userRole && userRole === 'administrator' && <Link className="link" to="/admin">
      <MenuItem active={isActive('/admin')} onClick={() => { setIsReports(false); setPathname('/admin'); }}>
        <Admin />
        {t('ru.menu_panel.admin')}
      </MenuItem>
    </Link>}
  </Wrapper>;
}

export default LeftPanel;
