import React from 'react'

import List from '@material-ui/core/List'
import {
  Box,
  Grid,
  makeStyles,
  IconButton,
  Chip,
  Button,
  Tooltip,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { Wrapper, TabItem } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { hallSelector, getHall, hallSuccess, handlerChangeHall, setHallStatistics } from '../../../redux/hallReducer'
import Preloader from '../../Common/Preloader'
import { prettyNum } from '../../../utils/prettyNum'
import JackpotsList from '../../Jackpots/List'
import { Status } from '../../Users/List/styles'
import TablesTab from './Tables'
import Binding from '../../Halls/View/Binding'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import HallForm from '../Form'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { convertUTCDateToLocalDate } from '../../../utils/date'
import { authSelector } from '../../../redux/authReducer'
import { isConfirmType } from '../../../interfaces'
import GoBackBtn from '../../Common/GoBackBtn'
import EnhanceLink from '../../Common/EnhanceLink'
import { findPermission, findPermissionOr } from '../../../utils/find'
import { userSelector } from '../../../redux/userReducer'
import { gameSelector, getAllGames } from '../../../redux/gameReducer'
import Hotkeys from '../../Hotkeys'
import Item from './item'
import MathTab from './Math';
import { limitSelector } from '../../../redux/limitReducer';
import StatisticsTab from './statistics-tab'
import GamePackages from './game-packages'

function getYesOrNo(value: number | boolean) {
  if (value) {
    return 'yes';
  }
  return 'no';
}

function getReelAnimation(value: number) {
  if (value === 1) {
    return "лепестки";
  }
  return "классика";
}

function getKeyboardType(value: number) {
  switch (value) {
    case 1:
      return "сиреневая";
    case 2:
      return "серая";
    case 3:
      return "Таджикистан";
  }
  return value.toString();
}

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0'
  },
  backBtn: {
    position: 'absolute',
    top: '0',
    left: '0'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '100%'
  },
  chip: {
    margin: '.5vh',
    padding: '0 1vh'
  },
  balance: {
    cursor: 'pointer',
    color: 'white',
    backgroundColor: '#919191',
    padding: '3px',
    fontWeight: 'bold',
    width: 'max-content'
  },
  tables: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tablesCount: {
    width: '75px'
  }
}));

interface HallProps {
  hideHall: () => void
  hallId: null | number
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
  setIsCancel: (isCancel: isConfirmType) => void
  showPayment: () => void
  showDistribution: () => void
}

const Hall: React.FC<HallProps> = ({
  hideHall,
  hallId,
  showPayment,
  isChangeMode,
  setIsChangeMode,
}) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const [t] = useTranslation()

  const { data: hall, loading } = useSelector(hallSelector)
  const {
    role: { item: userRole },
    assigments: { items },
  } = useSelector(authSelector)
  const {
    users: { data: users },
  } = useSelector(userSelector)

  const {
    games: { items: games },
  } = useSelector(gameSelector)

  const { items: limitList } = useSelector(limitSelector);

  React.useEffect(() => {
    !games.length && dispatch(getAllGames(1, 50))
    // eslint-disable-next-line
  }, [])

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    hallId && dispatch(getHall(hallId))
    return () => {
      dispatch(hallSuccess(null))
    }
  }, [dispatch, hallId])

  const [tab, setTab] = React.useState(0)

  const returnTab = (tab_id: number) => {
    switch (tab_id) {
      case 0:
        return main()
      case 1:
        return <GamePackages packages={hall?.packages} />;
      case 2:
        return <TablesTab hallId={hallId} />
      case 3:
        return jackpot()
      case 4:
        return <Binding hall_id={hallId} />
      case 5:
        return limits()
      case 6:
        return <MathTab hallMath={hall?.math} />
      case 7:
        return <Hotkeys hallId={Number(hallId)} edit={false} />
      case 8:
        return <StatisticsTab hallId={Number(hallId)} math={hall?.math} setType={(f: number) => { }} statistics={hall?.statistics || null} setStatistics={(f: any) => { dispatch(setHallStatistics(f)); }} />
      default:
        break
    }
  }

  const getTemplateName = (template: number) => {
    switch (template) {
      case 0:
        return 'Золотой'
      case 1:
        return 'Красный'
      case 2:
        return 'Синий'
      case 3:
        return 'Лото'
      case 4:
        return 'CeS'
      default:
        break
    }
  }

  const main = () => {
    const isAdmin = userRole && userRole === 'administrator';
    // const isManager = isAdmin || (userRole && userRole === 'manager');
    return (
      <>
        <Grid item xs={12} md={6}>
          <Box display="flex">
            <b>{t('ru.halls.fields.user')}:</b>

            {hall?.owner && users.findIndex((u) => u.id === hall?.owner.id) !== -1 ? (
              <EnhanceLink pathname={`/users`} search={`?id=${hall?.owner.id}`}>
                {hall?.owner.login}
              </EnhanceLink>
            ) : (
              t('ru.common.is_absent')
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.name')}:</b> {hall?.name ? hall?.name : t('ru.common.is_absent')}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.company')}:</b> {hall?.company.name}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.city')}:</b> {hall?.city.name}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.adress')}:</b> {hall?.address ? hall?.address : 'Отсутствует'}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box onClick={() => showPayment()}>
            <b>{t('ru.halls.fields.balance')}:</b>

            <span className={classes.balance}>
              {prettyNum(hall?.balance)}
              {/* {(hall?currency.id)} */}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.currency')}:</b> {hall?.currency.id}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.created_at')}:</b>
            <Chip
              className={classes.chip}
              label={
                hall && (
                  <Box
                    style={{ width: '100%' }}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <span style={{ margin: '3px' }}>
                      {convertUTCDateToLocalDate(hall.created_at)}
                    </span>
                  </Box>
                )
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.cashback')}:</b>
            <Chip className={classes.chip} label={<span>{Number(hall?.cashback)}</span>} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.min_cashback_deposit')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{Number(hall?.min_cashback_deposit) / 100}</span>}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <b>{t('ru.halls.fields.status')}:</b>
            <Box display="flex">
              <Status active={!!hall!.status}>
                {!!hall!.status ? t('ru.common.status.active') : t('ru.common.status.inactive')}
              </Status>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <b>{t('Шаблон')}:</b>
            <Box display="flex">
              <Chip className={classes.chip} label={<span>{hall && getTemplateName(hall?.hall_template)}</span>} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <b>{t('Школа крупье')}:</b>
            <Box display="flex">
              <Chip className={classes.chip} label={!!hall?.is_school ? 'Включено' : 'Отключено'} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.default_denom')}:</b>
            <Chip className={classes.chip} label={<span>{Number(hall?.denomination) / 100}</span>} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <b>{t('ru.halls.fields.denominations')}:</b>
            <Box className={classes.chips}>
              {hall?.denominations.map((item) => {
                return <Chip className={classes.chip} key={item} label={<span>{Number(item) / 100}</span>} />
              })}
            </Box>
          </Box>
        </Grid>
        <Item name={'ru.halls.fields.keyboard_type'} value={getKeyboardType(hall?.keyboard_type || 1)} />
        <Item name={'ru.halls.fields.reel_animation'} value={getReelAnimation(hall?.reel_animation || 0)} />
        <Item name={'ru.halls.fields.show_currency'} value={'ru.halls.selectors.' + getYesOrNo(hall?.show_currency || 0)} />
        <Item name={'ru.halls.fields.language'} value={'ru.languages.' + (hall?.language || 'RUS')} />
        <Item name={'ru.halls.fields.jackpot_migration'} value={((hall?.jackpot_migration)?.toString() || "0") + "%"} />
        {isAdmin && <Item name={'ru.halls.fields.poll_inc'} value={((hall?.poll_inc)?.toString() || "10")} />}
        {findPermissionOr(items, ['hallChildrenUpdateLimit', 'updateHallLimit']) && <Item name={'ru.halls.fields.limit'} value={(hall?.limit_id ? limitList.find((limit) => limit.id === hall.limit_id)?.name : "нет") || ""} />}
        <Item name={'ru.halls.fields.oferta'} value={t('ru.halls.selectors.oferta' + hall?.oferta)} />
        <Grid item xs={12} md={12}>
          <Box display="flex" justifyContent="center">
            <Button
              color="default"
              variant="contained"
              onClick={() => {
                history.push({
                  pathname: location.pathname,
                  search: location.search + '&operations_page=1',
                  state: {
                    prevPaths:
                      location.state && location.state.prevPaths ? location.state.prevPaths : [],
                  },
                })
              }}
              style={{ margin: '10px' }}
            >
              {t('ru.common.history_operations_btn')} <MenuOpenIcon style={{ margin: '0 10px' }} />
            </Button>
          </Box>
        </Grid>
      </>
    )
  }

  const jackpot = () => {
    return <JackpotsList hall_id={hallId} />
  }

  const limits = () => {
    return (
      <>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.max_win_from_deposit')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{Number(hall?.max_win_from_deposit)}</span>}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.max_win_per_spin')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{prettyNum(hall?.max_win_per_spin)}</span>}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.win_per_spin_percent_bet')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{Number(hall?.win_per_spin_percent_bet)}</span>}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.win_from_deposit_for_lose')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{Number(hall?.win_from_deposit_for_lose)}</span>}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <b>{t('ru.halls.fields.max_deposit_for_lose')}:</b>
            <Chip
              className={classes.chip}
              label={<span>{prettyNum(hall?.max_deposit_for_lose)}</span>}
            />
          </Box>
        </Grid>
      </>
    )
  }
  const history = useHistory()
  const location: any = useLocation()
  // const prevPath = location.state?.prevPath ? new URL(location.state.prevPath).search : null
  const rootRender =
    !hall || loading ? <Preloader /> : (
      <>
        {location.state && location.state.prevPaths && !!location.state.prevPaths.length && <GoBackBtn />}
        <Box className={classes.closeBtn}>
          {findPermission(items, 'viewHall') && findPermission(items, 'updateHall') && (
            <Tooltip title={isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))} placement="top" >
              <IconButton onClick={() => setIsChange(!isChange)}>
                <Box display="flex" justifyContent="center" alignItems="center" style={{ background: '#b6b6b6b6', width: '30px', minHeight: '30px', borderRadius: '50%', padding: '6px' }} >
                  <img style={{ width: '20px', minHeight: 'auto' }} src={!isChange ? editPng : visionPng} alt="icon" />
                </Box>
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={() => { history.push('halls'); }} >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center">
          <AccountCircleIcon fontSize="large" />
          <h2>{hall?.name}</h2>
        </Box>
        {!isChange ? (
          <>
            <Box className="tabs" display="flex" justifyContent="flex-start" style={{ overflowX: 'auto' }} >
              <TabItem active={tab === 0} onClick={() => setTab(0)} >
                {t('ru.halls.tabs.info')}
              </TabItem>
              <TabItem active={tab === 1} onClick={() => setTab(1)} >
                {t('ru.halls.tabs.packets')}
              </TabItem>
              <TabItem active={tab === 2} onClick={() => setTab(2)} >
                {t('ru.halls.tabs.tables')}
              </TabItem>
              {userRole && userRole !== 'cashier' && <TabItem active={tab === 3} onClick={() => setTab(3)} >
                {t('ru.halls.tabs.jackpots')}
              </TabItem>}
              {userRole && userRole !== 'cashier' && <TabItem active={tab === 4} onClick={() => setTab(4)} >
                {t('ru.halls.tabs.binding')}
              </TabItem>}
              {findPermissionOr(items, ['showHallMath', 'showChildrenHallMath']) && <TabItem active={tab === 6} onClick={() => setTab(6)} >
                {t('Математика')}
              </TabItem>}
              {findPermissionOr(items, ['showHallMath', 'showChildrenHallMath']) && <TabItem active={tab === 8} onClick={() => setTab(8)} >
                {t('Статистика')}
              </TabItem>}
              <TabItem active={tab === 7} onClick={() => setTab(7)} >
                {t('ru.halls.tabs.hotkeys')}
              </TabItem>
            </Box>
            <List>
              <div className={classes.root}>
                <Grid container spacing={2}>
                  {returnTab(tab)}
                </Grid>
              </div>
            </List>
          </>
        ) : (
          <HallForm
            onSubmit={(data, packages, math, hideForm, hallId, info) =>
              dispatch(handlerChangeHall(data, packages, math, hideForm, hallId, info))
            }
            cancelBtnEvent={hideHall}
            cancelBtnText={t('ru.common.back_btn')}
            submitBtnText={t('ru.common.submit_btn')}
            id={hall.id}
            name={hall.name}
            city_id={hall.city_id}
            limit_id={hall.limit_id}
            currency_id={hall.currency_id}
            company_id={hall.company_id}
            owner_id={hall.owner_id}
            cashback={hall.cashback}
            out_percent={hall.out_percent}
            poll_inc={hall.poll_inc}
            address={hall.address}
            status={hall.status}
            packets={hall.packages}
            denomination={hall.denomination}
            denominations={hall.denominations}
            keyboard_type={hall.keyboard_type}
            show_currency={hall.show_currency}
            language={hall.language}
            jackpot_migration={hall.jackpot_migration}
            reel_animation={hall.reel_animation}
            max_win_from_deposit={hall.max_win_from_deposit}
            max_win_per_spin={hall.max_win_per_spin}
            win_per_spin_percent_bet={hall.win_per_spin_percent_bet}
            win_from_deposit_for_lose={hall.win_from_deposit_for_lose}
            max_deposit_for_lose={hall.max_deposit_for_lose}
            min_cashback_deposit={hall.min_cashback_deposit}
            math_type={hall.math_type}
            hall_template={hall.hall_template}
            is_social_poll={hall.is_social_poll}
            show_logo={hall.show_logo}
            text={hall.text}
            is_school={hall.is_school}
            oferta={hall.oferta}
            hideForm={() => setIsChange(false)}
            tab={tab}
            setTab={setTab}
            math={hall?.math}
            change
          />
        )}
      </>
    )

  return <Wrapper>{rootRender}</Wrapper>
}

export default Hall
