import React from 'react'
import { Wrapper, MenuItem } from './styles'
import { Main, Business, Users, Terminals, Reports, Admin } from '../Common/MenuIcons'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import AssessmentIcon from '@material-ui/icons/Assessment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Collapse, List, ListItem, makeStyles, Theme } from '@material-ui/core'
import { authSelector } from '../../redux/authReducer'
import { useSelector } from 'react-redux'
import { findPermission } from '../../utils/find'

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(10),
    color: '#666',
    fontSize: '85%',
    paddingTop: 4.5,
    paddingBottom: 4.5,
  },
  bold: {
    fontWeight: 'bold',
  },
}))

interface MobileNavProps {
  isMobileNav: boolean
  setIsMobileNav: () => void
}

const MobileNav: React.FC<MobileNavProps> = ({ isMobileNav, setIsMobileNav }) => {
  const location = useLocation();
  const classes = useStyles();
  const [t] = useTranslation();

  const {
    assigments: { items },
    role: { item: userRole },
  } = useSelector(authSelector);

  const [pathname, setPathname] = React.useState<string>('')
  const [isReports, setIsReports] = React.useState<boolean>(false)

  const isActive = (path: string) => pathname.indexOf(path) !== -1
  React.useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname])
  const isNotCashier = userRole && userRole !== 'cashier';
  return (
    <Wrapper isMobileNav={isMobileNav}>
      <Link to="/">
        <MenuItem active={pathname === '/'} onClick={() => { setIsMobileNav(); setPathname('/'); }}>
          <Main />
          {t('ru.menu_panel.home')}
        </MenuItem>
      </Link>
      {(findPermission(items, 'viewUserList') || findPermission(items, 'userChildrenList')) && <Link to="/users">
        <MenuItem active={isActive('/users')} onClick={() => { setIsMobileNav(); setPathname('/users'); }}>
          <Users />
          {t('ru.menu_panel.users')}
        </MenuItem>
      </Link>}
      {findPermission(items, 'viewHallList') && <Link to="/halls">
        <MenuItem active={isActive('/halls')} onClick={() => { setIsMobileNav(); setPathname('/halls'); }}>
          <Business />
          {t('ru.menu_panel.halls')}
        </MenuItem>
      </Link>}
      {findPermission(items, 'viewPlayerList') && <Link to="/players">
        <MenuItem active={isActive('/players')} onClick={() => { setIsMobileNav(); setPathname('/players'); }}>
          <Terminals />
          {t('ru.menu_panel.players')}
        </MenuItem>
      </Link>}
      {userRole && userRole === 'administrator' && <Link to="/terminals">
        <MenuItem active={isActive('/terminals')} onClick={() => { setIsMobileNav(); setPathname('/terminals'); }}>
          <AssessmentIcon style={{ fontSize: '2.2vh', margin: '2vh' }} />
          {t('ru.menu_panel.terminals')}
        </MenuItem>
      </Link>}
      {findPermission(items, 'viewGamblingTableList') && <Link to="/tables">
        <MenuItem active={isActive('/tables')} onClick={() => { setIsMobileNav(); setPathname('/tables'); }}>
          <ImportantDevicesIcon style={{ fontSize: '2.2vh', margin: '2vh' }} />
          {t('ru.menu_panel.tables')}
        </MenuItem>
      </Link>}
      {findPermission(items, 'jackpotIndex') && <Link to="/jackpots">
        <MenuItem active={isActive('/jackpots')} onClick={() => { setIsMobileNav(); setPathname('/jackpots'); }}>
          <ImportantDevicesIcon style={{ fontSize: '2.2vh', margin: '2vh' }} />
          {t('ru.menu_panel.jackpots')}
        </MenuItem>
      </Link>}
      {
        <>
          <MenuItem active={isActive('/reports')} onClick={() => { /*setPathname('/reports')*/setIsReports(!isReports); }}>
            <Reports />
            {t('ru.menu_panel.reports')}
            {isReports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </MenuItem>
          <Collapse in={isReports} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {findPermission(items, 'hallReportAmount') && <Link className="link" to="/reports/main">
                <ListItem className={classes.nested} onClick={() => { setIsMobileNav(); setPathname('/reports/halls'); }}>
                  <span style={pathname === '/reports/main' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                    {t('ru.reports.halls_title')}
                  </span>
                </ListItem>
              </Link>}
              <Link className="link" to="/reports/cashboxes">
                <ListItem className={classes.nested} onClick={() => { setIsMobileNav(); setPathname('/reports/cashboxes'); }}>
                  <span style={pathname === '/reports/cashboxes' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                    {t('ru.reports.shifts_title')}
                  </span>
                </ListItem>
              </Link>
              {isNotCashier && <Link className="link" to="/reports/games">
                <ListItem className={classes.nested} onClick={() => { setIsMobileNav(); setPathname('/reports/games'); }}>
                  <span style={pathname === '/reports/games' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                    {t('ru.reports.games_title')}{' '}
                  </span>
                </ListItem>
              </Link>}
              {isNotCashier && <Link className="link" to="/reports/user">
                <ListItem className={classes.nested} onClick={() => setPathname('/reports/user')}>
                  <span style={pathname === '/reports/user' ? { fontWeight: 'bold', color: 'rgb(116,103,236)' } : {}}>
                    {t('Отчет пользователя')}{' '}
                  </span>
                </ListItem>
              </Link>}
            </List>
          </Collapse>
        </>
      }
      {true && <Link className="link" to="/faq">
        <MenuItem active={isActive('/faq')} onClick={() => { setIsMobileNav(); setPathname('/faq'); }}>
          <HelpOutlineIcon style={{ margin: '0 2vh', fontSize: '15px' }} />
          FAQ
        </MenuItem>
      </Link>}
      {userRole && userRole === 'administrator' && <Link to="/admin">
        <MenuItem active={isActive('/admin')} onClick={() => { setIsMobileNav(); setPathname('/admin'); }}>
          <Admin />
          {t('ru.menu_panel.admin')}
        </MenuItem>
      </Link>}
    </Wrapper>
  )
}

export default MobileNav
