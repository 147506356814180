import React from 'react'
import { Wrapper } from './styles'
import { IconButton, makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSelector, useDispatch } from 'react-redux'
import { hallSelector, hallSuccess, getHall } from '../../../redux/hallReducer'
import Preloader from '../../Common/Preloader'
import { HallData, IHallMathForm } from '../../../interfaces'
import HallForm from '../Form'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))

interface ChangeHallProps {
  hideChangeHall: () => void
  handlerChangeHall: (
    data: HallData,
    packagesData: any,
    math: IHallMathForm,
    hideForm: () => void,
    hallId: number | undefined,
    info?: boolean
  ) => void
  hallId: number | undefined
}

const ChangeHall: React.FC<ChangeHallProps> = ({ hideChangeHall, handlerChangeHall, hallId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  React.useEffect(() => {
    hallId && dispatch(getHall(hallId))
    return () => {
      dispatch(hallSuccess(null))
    }
  }, [dispatch, hallId])

  const { data: hall, loading } = useSelector(hallSelector)
  const [tab, setTab] = React.useState(0)
  return (
    <Wrapper>
      <IconButton className={classes.closeBtn} onClick={hideChangeHall}>
        <CloseIcon />
      </IconButton>
      <h3>Изменение зала</h3>
      {loading || !hall ? <Preloader absolute={false} /> : (
        <>
          <HallForm
            onSubmit={handlerChangeHall}
            cancelBtnEvent={hideChangeHall}
            cancelBtnText={'Назад'}
            submitBtnText={'Подтвердить'}
            id={hall.id}
            name={hall.name}
            city_id={hall.city_id}
            limit_id={hall.limit_id}
            currency_id={hall.currency_id}
            company_id={hall.company_id}
            owner_id={hall.owner_id}
            cashback={hall.cashback}
            out_percent={hall.out_percent}
            poll_inc={hall.poll_inc}
            address={hall.address}
            status={hall.status}
            keyboard_type={hall.keyboard_type}
            reel_animation={hall.reel_animation}
            show_currency={hall.show_currency} language={hall.language} jackpot_migration={hall.jackpot_migration}
            packets={hall.packages}
            denomination={hall.denomination}
            denominations={hall.denominations}
            max_win_from_deposit={hall.max_win_from_deposit}
            max_win_per_spin={hall.max_win_per_spin}
            win_per_spin_percent_bet={hall.win_per_spin_percent_bet}
            win_from_deposit_for_lose={hall.win_from_deposit_for_lose}
            max_deposit_for_lose={hall.max_deposit_for_lose}
            min_cashback_deposit={hall.min_cashback_deposit}
            math_type={hall.math_type}
            hall_template={hall.hall_template}
            is_social_poll={hall.is_social_poll}
            show_logo={hall.show_logo}
            text={hall.text}
            hideForm={hideChangeHall}
            setTab={setTab}
            tab={tab}
            math={hall?.math}
            change oferta={hall.oferta}          />
        </>
      )}
    </Wrapper>
  )
}

export default ChangeHall
