import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authReducer';
import userReducer from './userReducer';
import hallReducer from './hallReducer';
import countryReducer from './countryReducer';
import cityReducer from './cityReducer';
import companyReducer from './companyReducer';
import currencyReducer from './currencyReducer';
import playerReducer from './playerReducer';
import profileReducer from './profileReducer';
import tableReducer from './tableReducer';
import gameReducer from './gameReducer';
import manufacturerReducer from './manufacturerReducer';
import packageReducer from './packageReducer';
import roleReducer from './roleReducer';
import permissionReducer from './permissionReducer';
import defaultPackageReducer from './defaultPackageReducer';
import transfersReducer from './transfersReducer';
import assignmentReducer from './assignmentReducer';
import paymentReducer from './paymentReducer';
import treeInfoReducer from './treeInfoReducer';
import jackpotReducer from './jackpotReducer';
import reportReducer from './reportReducer';
import cashboxReducer from './cashboxReducer';
import faqReducer from './faqReducer';
import hotkeyActionsReducer from './hotkeyActionsReducer';
import hotkeysReducer from './hotkeysReducer';
import limitReducer from './limitReducer';
import logReducer from './logReducer';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  hall: hallReducer,
  country: countryReducer,
  city: cityReducer,
  company: companyReducer,
  currency: currencyReducer,
  player: playerReducer,
  profile: profileReducer,
  table: tableReducer,
  game: gameReducer,
  manufacturer: manufacturerReducer,
  package: packageReducer,
  role: roleReducer,
  permission: permissionReducer,
  defaultPackage: defaultPackageReducer,
  transfers: transfersReducer,
  assignment: assignmentReducer,
  payment: paymentReducer,
  treeInfo: treeInfoReducer,
  jackpot: jackpotReducer,
  report: reportReducer,
  cashbox: cashboxReducer,
  faq: faqReducer,
  hotkeyActions: hotkeyActionsReducer,
  hotkeys: hotkeysReducer,
  limit: limitReducer,
  log: logReducer
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/clearState') {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;