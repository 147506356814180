import React from 'react'

import List from '@material-ui/core/List'
import { Box, Grid, makeStyles, IconButton, ListItem, Tooltip, Divider } from '@material-ui/core'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'

import { Wrapper } from './styles'
import Profile from '../../Profiles/View'
import { useShowBlock } from '../../../hooks/useShowBlock'
import { IPlayer, IProfile, PlayerData } from '../../../interfaces'
import editPng from '../../../assets/images/edit.png'
import visionPng from '../../../assets/images/vision.png'
import PlayerForm from '../Form'
import { handlerChangePlayer } from '../../../redux/playerReducer'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import GoBackBtn from '../../Common/GoBackBtn'
import { hallSelector } from '../../../redux/hallReducer'
import EnhanceLink from '../../Common/EnhanceLink'

const useStyles = makeStyles(() => ({
  closeBtn: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
}))
interface PlayerProps {
  player: IPlayer | undefined
  profile: IProfile | undefined
  hidePlayer: () => void
  setCurrentProfile: (id: number) => void
  showProfiles: () => void
  isChangeMode: boolean
  setIsChangeMode: (isChangeMode: boolean) => void
}
const Player: React.FC<PlayerProps> = ({
  player,
  profile,
  hidePlayer,
  setCurrentProfile,
  showProfiles,
  isChangeMode,
  setIsChangeMode,
}) => {
  const dispatch = useDispatch()
  const classes: any = useStyles()
  const location: any = useLocation()
  const [t] = useTranslation()

  const {
    halls: { data: halls },
  } = useSelector(hallSelector)

  const { showBlock: showProfile, isBlockShown: isProfile, hideBlock: hideProfile } = useShowBlock()

  const [isChange, setIsChange] = React.useState(isChangeMode)
  React.useEffect(() => {
    return () => {
      setIsChangeMode(false)
    }
    // eslint-disable-next-line
  }, [])

  const rootRender = (
    <>
      <Box className={classes.closeBtn}>
        {
          <Tooltip
            title={
              isChange ? String(t('ru.common.view_tooltip')) : String(t('ru.common.change_btn'))
            }
            placement="top"
          >
            <IconButton onClick={() => setIsChange(!isChange)}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  background: '#b6b6b6b6',
                  width: '30px',
                  minHeight: '30px',
                  borderRadius: '50%',
                  padding: '6px',
                }}
              >
                <img
                  style={{
                    width: '20px',
                    minHeight: 'auto',
                  }}
                  src={!isChange ? editPng : visionPng}
                  alt="icon"
                />
              </Box>
            </IconButton>
          </Tooltip>
        }
        <IconButton onClick={hidePlayer}>
          <CloseIcon />
        </IconButton>
        {!!location.state && !!location.state.prevPaths && !!location.state.prevPaths.length && (
          <GoBackBtn />
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <AccountCircleIcon fontSize="large" />
        <h2>{player?.name}</h2>
      </Box>
      <br />
      <Divider />
      {isChange ? (
        <PlayerForm
          onSubmit={(data: PlayerData, hideForm: () => void, id: number | undefined) =>
            dispatch(handlerChangePlayer(data, hideForm, id))
          }
          showProfiles={showProfiles}
          profile={profile}
          id={player?.id}
          name={player?.name}
          hall_id={player?.hall_id}
          cancelBtnEvent={() => setIsChange(false)}
          cancelBtnText={t('ru.common.back_btn')}
          submitBtnText={t('ru.common.change_btn')}
          hideForm={() => setIsChange(false)}
        />
      ) : (
        <List>
          <div className={classes.root}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box>
                  <b>{t('ru.players.fields.name')}:</b> {player?.name ? player?.name : 'Отсуствует'}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box display="flex">
                  <b>{t('ru.players.fields.hall')}:</b>{' '}
                  <EnhanceLink pathname={`halls`} search={`?hall_id=${player?.hall_id}`}>
                    {halls && halls?.find((hall) => hall.id === player?.hall_id)?.name}
                  </EnhanceLink>
                </Box>
              </Grid>
            </Grid>
          </div>
          <br />
          <span>
            <b>{t('ru.players.fields.profile')}:</b>
          </span>
          {player?.profile ? (
            <ListItem
              onClick={() => {
                setCurrentProfile(-1)
                showProfile()
              }}
              className={classes.item}
              button
            >
              <Grid container>
                <Grid item xs={1}>
                  <span className={classes.span}>{player.profile.id}</span>
                </Grid>
                <Grid item xs={3}>
                  <span className={classes.span}>{player.profile.name}</span>
                </Grid>
                <Grid item xs={3}>
                  <span className={classes.span}>{player.profile.surname}</span>
                </Grid>
                <Grid item xs={3}>
                  <span className={classes.span}>{player.profile.patronymic}</span>
                </Grid>
              </Grid>
            </ListItem>
          ) : (
            <span>{t('ru.common.is_absent')}</span>
          )}
        </List>
      )}
    </>
  )

  return (
    <Wrapper>
      {rootRender}
      {isProfile && <Profile profile={player?.profile} hideProfile={hideProfile} />}
    </Wrapper>
  )
}

export default Player
