import React, { FunctionComponent } from 'react';
import { FormControl, FormHelperText, Grid, NativeSelect } from '@material-ui/core';
import { ILimit } from '../../../../../interfaces';
import { useSelector } from 'react-redux';
import { limitSelector } from '../../../../../redux/limitReducer';

type FieldLimitProps = {
    error: boolean;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    limitId: number;
    formHelperText: string;
};

function cmp(prev: FieldLimitProps, cur: FieldLimitProps): boolean {
    return prev.error === cur.error && prev.formHelperText === cur.formHelperText && prev.limitId === cur.limitId;
}

const FieldLimit: FunctionComponent<FieldLimitProps> = React.memo(({ error, onChange, limitId, formHelperText }) => {
    const { items: limitList } = useSelector(limitSelector);
    return <Grid item xs={12} sm={4}>
        <FormControl fullWidth size="small" error={error}>
            <NativeSelect onChange={onChange} value={limitId} >
                <option aria-label="None" value="" />
                {limitList && limitList.map((item: ILimit) => <option value={item.id} key={item.id}> {item.name} </option>)}
            </NativeSelect>
            <FormHelperText> {formHelperText} </FormHelperText>
        </FormControl>
    </Grid>;
}, cmp);

export default FieldLimit;